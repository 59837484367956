<template lang="pug">
  //- parent should be .flex[.items-stretch] so this el is stretched to fit height
  .relative.w-full.flex.justify-center(:class="{'items-center': fullWidth}")
    //- only crop while blur active
    div(:style="style", :class="{'relative overflow-hidden': blurOn, 'opacity-0': !elW}")
      slot
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'BlurImageFrame',
  props: ['dims'],
  data () {
    return {
      elW: 0,
      elH: 0,
      blurOn: true // active at load always
    }
  },
  computed: {
    ...mapState(['blur']),
    elAR () {
      return this.elH / this.elW
    },
    imgAR () {
      return this.dims.height / this.dims.width
    },
    fullWidth () {
      return this.imgAR < this.elAR
    },
    style () {
      if (this.fullWidth) {
        return {
          width: '100%',
          paddingBottom: `calc(100% * ${this.imgAR})`
        }
      }
      return {
        // will inherit height from .flex $el parent
        width: `calc(${this.elH} * (${this.dims.width} / ${this.dims.height}) / ${this.elW} * 100%)`
      }
    }
  },
  methods: {
    measure () {
      this.elW = this.$el.offsetWidth
      this.elH = this.$el.offsetHeight
    }
  },
  watch: {
    blur (blur) {
      if (!blur) {
        setTimeout(() => {
          this.blurOn = false
        }, 1600) // WELL after blur dur (_settings.css)
      }
    }
  },
  mounted () {
    this.measure()
  }
}
</script>

<style>
.blur-image-frame__frame{
  transition: overflow 0s 2000ms, position 0s 2000ms;
  position: static;
}
.blur-media .blur-image-frame__frame{
  overflow: hidden;
  position: relative;
  /*transition:none;*/
}
</style>
