<template lang="pug">
  #app.font-sans.leading-normal.tracking-40.font-numbers-oldstyle
    vue-progress-bar.z-50
    app-header.fixed.top-0.left-0.z-30(:hideNav="loading")
    main.trans-opacity-quick(:class="{'invisible opacity-0 h-screen overflow-hidden': loading, 'min-h-screen': $route.meta.minH !== false}")
      transition(:name="anim", v-on:before-leave="beforeLeave", v-on:after-leave="afterLeave", v-on:before-enter="beforeEnter", v-on:after-enter="afterEnter")
        router-view(:key="$route.path", :entering="entering", @onEndTo="onEndTo" @afterEnter="anim = null")
</template>

<script>
import '@/style/_main.css'
import AppHeader from '@/components/AppHeader/AppHeader'
import { mapState } from 'vuex'
import _throttle from 'lodash/throttle'
export default {
  name: 'App',
  data () {
    return {
      y: 0,
      lastRtY: 0,
      loading: true,
      anim: null,
      entering: true,
      afterResize: null
    }
  },
  computed: {
    ...mapState(['bus'])
  },
  methods: {
    onScroll: _throttle(function () {
      this.y = window.pageYOffset
      this.bus.$emit('newScrollY', this.y)
    }, 20),
    onResize: _throttle(function () {
      clearTimeout(this.afterResize)
      this.afterResize = setTimeout(() => {
        const update = (value, old, emit, commit) => {
          if (value === old) return
          this.$store.commit(commit, value)
          this.bus.$emit(emit)
        }
        update(window.innerWidth, this.winW, 'newWindowWidth', 'SET_WIN_W')
        update(window.innerHeight, this.winH, 'newWindowHeight', 'SET_WIN_H')
      }, 100)
    }, 50),
    setAnim (to, from, anim) {
      // if preset, skip
      if (this.anim) return
      // else based on rt
      const order = ['/projects', '/info', '/press', '/editions']
      const tt = order.findIndex(itm => to.path.startsWith(itm))
      const ff = order.findIndex(itm => from.path.startsWith(itm))
      this.anim = (to.meta.transition || from.meta.transition) ? to.meta.transition // set in router.js
        : !from.name ? 'pagefade' // entered site
          : from.name === 'home' ? 'pageslide-fixed-left' // to home
            : to.name === 'home' ? 'pageslide-fixed-right' // from home
              : from.name === 'project' && to.name === 'projects' ? 'pageslide-fixed-right' // prj > prjs
                : from.name === 'project' && to.name === 'project' ? 'slideup' // prj > prj
                  : (ff < 0 && tt > -1) || ff > tt ? 'pageslide-fixed-right' // slide rightward
                    : ff < tt ? 'pageslide-fixed-left' // slide leftward
                      : 'pagefade' // default
    },
    beforeLeave (el) {
      if (this.anim === 'pagefade') return
      this.lastRtY = this.y
      el.style.height = '100vh'
      el.style.overflowY = 'hidden'
      el.scroll(0, this.lastRtY)
    },
    afterLeave (el) {
      if (this.anim === 'pagefade') window.scroll(0, 0)
    },
    beforeEnter (el) {
      this.entering = true
      this.$store.commit('SET_BLUR', true)
      el.classList.add('blur-media')
    },
    afterEnter (el) {
      this.entering = false
      this.anim = null
      const delay = this.loading ? 1450 : 450
      setTimeout(() => {
        el.classList.remove('blur-media')
        this.$store.commit('SET_BLUR', false)
      }, delay)
    },
    onEndTo (route) {
      // slide up transition into route
      this.anim = 'project-to-projects'
      this.$router.push({ name: route, query: { s: 0 } })
    }
  },
  watch: {
    '$route' (to, from) {
      if (to.path === from.path) return
      this.setAnim(to, from)
    }
  },
  mounted () {
    // TODO - better loading? just use rts to commit to state:loading ?
    this.$Progress.start()
    window.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.onResize)
    setTimeout(() => {
      this.$Progress.finish()
      this.loading = false
    }, 1000)
    this.$store.dispatch('shop/fetchCheckout')
  },
  destroyed () {
    window.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.onResize)
  },
  components: { AppHeader }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
