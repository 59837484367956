const productItemBody = `
  title
  handle
  vendor
  productType
  availableForSale
  priceRange {
    minVariantPrice {
      amount
      # currencyCode
    }
  }
  images (first: 2) {
    edges {
      node {
        originalSrc
        altText
      }
    }
  }
  tags
  variants (first:2) {
    edges {
      node {
        # availableForSale
        # use product.priceRange....
        # priceV2 {
          # amount
        # }
        selectedOptions {
          name
          value
        }
      }
    }
  }
  options {
    name
    values
  }
`

const productBody = `
  availableForSale
  title
  handle
  vendor
  productType
  # description
  # descriptionHtml
  priceRange {
    minVariantPrice {
      amount
      # currencyCode
    }
  }
  # images (first:20) {
    # edges {
      # node {
        # altText
        # originalSrc
        # meta: transformedSrc (maxWidth: 1200)
      # }
    # }
  # }
  options {
    name
    values
  }
  variants (first:12) {
    edges {
      node {
        availableForSale
        id
        title
        sku
        quantityAvailable
        currentlyNotInStock
        priceV2 {
          amount
          currencyCode
        }
        # image {
          # altText
          # originalSrc
        # }
        selectedOptions {
          name
          value
        }
      }
    }
  }
  # tags
  # collections (first:3) {
    # edges {
      # node {
        # handle
        # title
      # }
    # }
  # }
  # metafields (namespace:"aro", first: 1) {
    # edges {
      # node {
        # key
        # value
      # }
    # }
  # }
`

const checkoutBody = `
  id
  completedAt
  webUrl
  subtotalPriceV2 {
    amount
    currencyCode
  }
  totalPriceV2 {
    amount
    currencyCode
  }
  lineItems (first:250) {
    edges {
      node {
        id
        quantity
        title
        unitPrice {
          amount
          currencyCode
        }
        variant {
          availableForSale
          id
          title
          sku
          priceV2 {
            amount
            currencyCode
          }
          image {
            altText
            originalSrc
          }
          selectedOptions {
            name
            value
          }
        }
        customAttributes {
          key
          value
        }
      }
    }
  }
  shippingAddress {
    countryCodeV2
  }
`

export const queryProducts = (full = false) => `
  query ($first: Int!, $after: String, $query: String, $sortKey: ProductSortKeys, $reverse: Boolean) {
    products (first: $first, query: $query, after: $after, sortKey: $sortKey, reverse: $reverse) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          ${full ? productBody : productItemBody}
        }
      }
    }
  }
`

export const queryProduct = `
  query ($handle: String!) {
    productByHandle (handle: $handle) {
      ${productBody}
    }
  }
`

export const queryCheckout = `
  query ($id: ID!) {
    node (id: $id) {
      ...on Checkout {
        ${checkoutBody}
      }
    }
  }
`
export const mutateCreateCheckout = `
  mutation checkoutCreate($input: CheckoutCreateInput!) {
    checkoutCreate(input: $input) {
      checkout {
        ${checkoutBody}
      }
    }
  }
`

export const mutateCheckoutItems = `
  mutation ($id: ID!, $lineItems: [CheckoutLineItemInput!]!) {
    checkoutLineItemsReplace (checkoutId: $id, lineItems: $lineItems) {
      checkout {
        ${checkoutBody}
      }
    }
  }
`

// export const queryCollection = `
//   query ($handle: String!, $first: Int!, $after: String, $imgMaxW: Int, $sortKey: ProductCollectionSortKeys, $reverse: Boolean) {
//     collectionByHandle (handle: $handle) {
//       title
//       handle
//       description
//       products (first:$first, after: $after, sortKey: $sortKey, reverse: $reverse) {
//         pageInfo {
//           hasNextPage
//         },
//         edges {
//           cursor
//           node {
//             ${productItemBody}
//           }
//         }
//       }
//     }
//   }
// `

// SHOP POLICIES

export const queryShopPolicy = (policyHandle) => `
  query {
    shop {
      ${policyHandle} {
        title
        body
      }
      # handles:
      # refundPolicy
      # shippingPolicy
      # privacyPolicy
      # termsOfService
    }
  }
`
