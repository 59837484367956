<template lang="pug">
  .app-header.w-full.trans-theme.leading-none.tracking-40.pb-24.font-numbers-normal(:class="theme === 'dark' ? 'text-white' : 'text-black'", @mouseenter="$emit('hover')", @mouseleave="$emit('hoverEnd')")
    //- logo
    .absolute.z-10.top-0.left-0.w-full.px-12.h-topbar-lg.lg-pb-26.pointer-events-none.flex.items-end
      .w-4x18.px-12.text-24.font-medium.whitespace-no-wrap(style="line-height:0.75")
          a.pointer-events-auto(href="/", @click.prevent="$emit('refresh')") Guido van Helten

    //- bar
    .flex.items-end.px-12.h-topbar-lg.relative.lg-pb-26(:style="{transform: collapsed ? 'translateY(-25%)' : 'none'}", style="transform-origin:left bottom; transition: opacity 150ms, transform 150ms, background-color 150ms", :class="{'opacity-0': collapsed, 'bg-white': y > 10 && canCollapse}")
      //- logo space
      .w-4x18.px-12

      //- main nav
      transition(name="quickfade")
        nav.app-header__nav.w-7x18.xl-w-6x18.px-12.text-15.whitespace-no-wrap(v-show="!hideNav")
          router-link.inline-block.mr-2sp(:to="{name: 'home'}") Overview
          router-link.inline-block.mr-2sp.app-header__parent-rt(:to="{name: 'projects'}") Projects
          router-link.inline-block.mr-2sp(:to="{name: 'page', params: {uid: 'info'}}") Information
          router-link.inline-block.mr-2sp(:to="{name: 'page', params: {uid: 'press'}}") Press
          router-link.inline-block.mr-2sp(:to="{name: 'editions'}") Editions

      //- (projects: view nav)
      nav.app-header__nav.px-12.text-15(v-show="!hideNav && $route.name === 'projects'")
        router-link(:to="{query: {view: 'image'}}", :class="{'font-medium': !$route.query.view}").inline-block.mr-2sp Image
        router-link(:to="{query: {view: 'map'}}").inline-block.mr-2sp Map
        router-link(:to="{query: {view: 'text'}}").inline-block.mr-2sp Text

      //- (project: scroll nav)
      nav.app-header__nav.hidden.xl-block.w-4x18.px-12.text-15.trans-opacity-quick.whitespace-no-wrap(v-show="!hideNav && $route.name === 'project'", :class="!nav && 'opacity-0'")
        button.inline-block.mr-2sp(v-scroll-to="'#prj-mural'", :class="{'font-medium': active === 'prj-mural'}") Mural
        button.inline-block.mr-2sp(v-scroll-to="{el: '#prj-body', offset: -144}", :class="{'font-medium': active === 'prj-body'}") Text
        button.hidden.mr-2sp(v-for="(itm, i) in nav", v-scroll-to="{el: '#' + idy(itm), offset: -144}", :class="{'lg-inline-block': !i, 'xl-inline-block': i > 0, 'font-medium': active === idy(itm)}") {{itm}}

      //-
      .flex-1.flex.justify-end.text-15.px-12
        //- page nav (left align)
        nav.flex-1.trans-opacity-quick(v-show="!hideNav && $route.name === 'page'", :class="!nav && 'opacity-0'")
          button.mr-2sp(v-for="(itm, i) in nav", v-scroll-to="{el: '#' + idy(itm)}", :class="{'font-medium': active === idy(itm)}") {{itm}}

        //- project nav (right align)
        project-nav.flex-1(v-show="!hideNav && $route.name === 'project'")

        //- bag link (rightmost)
        router-link.block.whitespace-no-wrap.ml-2sp(v-if="cartCount", :to="{name: 'cart'}") {{ cartCount }} Bag

    //- bar (collapsed state)
    //- .absolute.inset-0.flex.items-end.px-24.h-topbar-lg.lg-pb-26.z-10.pointer-events-none(:class="{'opacity-0': !collapsed}", style="transition: 100ms")
      .relative.text-15.w-full(:style="{transform: collapsed ? 'translateY(-95%)' : 'none'}", style="transition: 200ms") &nbsp;
        .absolute.inset-0.flex.items-center
          .h-2.bg-black.w-full
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { idy } from '@/utils'
import ProjectNav from '@/components/Project/ProjectNav'
export default {
  name: 'AppHeader',
  props: ['hideNav', 'collapsed', 'y', 'canCollapse'],
  data () {
    return {
      idy: idy,
      sections: []
    }
  },
  computed: {
    ...mapState(['theme', 'bus', 'nav']),
    ...mapGetters({ cartCount: 'shop/cartCount' }),
    active () {
      // active scroll pt in scroll nav
      const sec = this.sections.find(sec => this.y >= sec.top)
      return sec && sec.id
    }
  },
  methods: {
    setSections (delay = 800) {
      setTimeout(() => {
        const els = document.querySelectorAll('.scroll-pt')
        const top = window.pageYOffset
        els.forEach(el => this.sections.push(
          { id: el.getAttribute('id'), top: el.getBoundingClientRect().top + top - 144 }
        ))
        this.sections.reverse()
      }, delay)
    }
  },
  watch: {
    nav () {
      this.setSections()
    }
  },
  components: { ProjectNav }
}
</script>

<style>
.app-header .app-header__nav .router-link-exact-active,
.app-header .app-header__parent-rt.router-link-active.router-link-active {
  font-weight: 500
}
</style>
