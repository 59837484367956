<template lang="pug">
  //- layout modeled after ProjectHeader.vue
  article.pt-72.-mb-24.px-12
    .min-h-158.md-min-h-300.flex.items-center.justify-center
      .text-20.md-text-24.text-center.py-48.md-py-112
        h1.font-medium.mt-em
          | {{ $route.meta.title || 'An Error Occured' }}

    .text-13.md-text-16.text-center
      //- (info?)
      p.mb-em(v-if="$route.meta.info") {{ $route.meta.info }}

      //- (call to action button?)
      template(v-if="$route.meta.cta")
        template(v-if='$route.meta.cta.route')
          router-link(:to="$route.meta.cta.route") {{ $route.meta.cta.text }}

</template>

<script>
export default {
  name: 'NotFound'
}
</script>

<style>
</style>
