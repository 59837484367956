import PrismicDOM from 'prismic-dom'
import linkResolver from '@/prismic-link-resolver'
import kebabCase from 'lodash/kebabCase'

export function canObserve () {
  return typeof window.IntersectionObserver === 'function'
}

// Imgix thumb resizing
// https://docs.imgix.com/setup/serving-images
export function prismicThumb (src, length, byHeight = false) {
  let url
  const sizes = [320, 480, 640, 800, 1024, 1280, 1440, 1600, 1920, 2560, 2880, 3600, 4096, 5120]
  const dpx = window.devicePixelRatio || 1
  try {
    url = new URL(src)
  } catch (e) {
    console.warn('Invalid URL', src)
    return src
  }
  // w/h already set ?
  const w = url.searchParams.get('w')
  const h = url.searchParams.get('h')
  // update length
  length = parseInt(length * dpx * 0.8) // less density optically ok ?
  // find optimal
  let size = sizes.find(sz => length <= sz) || sizes[sizes.length - 1]
  // resize
  const key = byHeight ? 'h' : 'w'
  url.searchParams.set(key, size)
  // preserve aspect ratio (if defined)
  if (byHeight && w) {
    url.searchParams.set('w', parseInt(size * w / h))
  } else if (h) {
    url.searchParams.set('h', parseInt(size * h / w))
  }
  return url.href
}

export function formatPrjs (prjs) {
  const ratios = { '4:3': 'pb-ar-4x3', '4:5': 'pb-ar-4x5', '1:1': 'pb-ar-1x1' }
  return prjs.map(prj => {
    const ar = prj && prj.data && prj.data.index_image__format
    prj.data._index_image = ar ? prj.data.index_image[ar] : prj.data.index_image
    prj.data._ar_class = ratios[ar] || ratios['4:3']
    return prj
  })
}

export function next (cur, step, total) {
  return cur + step === total ? 0 : cur + step < 0 ? total - 1 : cur + step
}

export function getNav (slices) {
  slices = slices || []
  return slices.filter(slc => slc.slice_type === 'scroll_point').map(pt => {
    const lbl = pt.primary.label
    return typeof lbl === 'string' ? lbl : lbl[0] && lbl[0].text
  })
}

export function idy (str) {
  // format ids for scroll points (numbers cant be DOM id)
  return '_' + kebabCase(str)
}

export function superScriptRichText (field) {
  // convert "[2]" footnotes to <sup>2</sup>
  if (!field) return ''
  const text = PrismicDOM.RichText.asHtml(field, linkResolver)
  const replacer = (match) => match.replace('[', '<sup>').replace(']', '</sup>')
  return text.replace(/\[{1}\d+\]{1}/g, replacer)
}

export function isMedia (slice) {
  return slice && ['image_folio', 'vimeo'].includes(slice.slice_type)
}

export function prjYear (project) {
  const date = project.data.mural_completion_date.split('-')
  return date && date[0]
}
