<template lang="pug">
  header.w-full(role="banner")
    app-header-landscape.hidden.lg-block(v-bind="$attrs", :collapsed="collapsed", :y="y", @hover="hover = true; collapsed = false", @hoverEnd="hover = false", @refresh="refreshPage", :canCollapse="canCollapse")
    app-header-mobile.block.lg-hidden(v-bind="$attrs", :collapsed="collapsed", @click.native="collapsed = false", @refresh="refreshPage", :y="y", :canCollapse="canCollapse")
</template>

<script>
import AppHeaderLandscape from './AppHeader--Landscape'
import AppHeaderMobile from './AppHeader--Mobile'
export default {
  name: 'AppHeader',
  data () {
    return {
      y: 0,
      collapsed: false,
      hover: false,
      afterScroll: null
    }
  },
  computed: {
    isMobile () {
      return !this.$store.getters.winIs('lg')
    },
    canCollapse () {
      return !this.$route.meta.headerNoCollapse
    }
  },
  methods: {
    onScroll (y) {
      clearTimeout(this.afterScroll)
      if (!this.canCollapse) {
        this.collapsed = false
        return
      }
      const scrollingDown = y > this.y
      this.collapsed = y > 0 && scrollingDown && !this.hover
      this.y = y
      this.afterScroll = setTimeout(() => {
        this.collapsed = false
      }, 1200)
    },
    refreshPage () {
      window.location = '/'
    }
  },
  watch: {
    '$route' () {
      this.collapsed = false
    }
  },
  mounted () {
    this.$store.state.bus.$on('newScrollY', this.onScroll)
  },
  components: {
    AppHeaderLandscape,
    AppHeaderMobile
  }
}
</script>

<style>
</style>
