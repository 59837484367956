<template lang="pug">
  .absolute.inset-0(:class="{'p-72 flex': frmt === 'inset'}")
    //- click target for ios
    .absolute.inset-0.z-10(@click="$emit('click')")
    //- inset
    template(v-if="frmt === 'inset'")
      .relative.w-full.z-0
        vimeo-player(ref="vimeo", :vimeoURL="vimeoURL", bgSize="contain", @loaded="onLoaded", :clip="[slice.primary.vimeo_clip_start, slice.primary.vimeo_clip_end]", @firstplay="onFirstPlay", v-if="loadVimeo", :visible="visible")
    //- full bleed
    template(v-else)
      vimeo-player.z-0(ref="vimeo", :vimeoURL="vimeoURL", bgSize="cover", @loaded="onLoaded", :clip="[slice.primary.vimeo_clip_start, slice.primary.vimeo_clip_end]", @firstplay="onFirstPlay", v-if="loadVimeo", :visible="visible")
</template>

<script>
import { mapGetters } from 'vuex'
import VimeoPlayer from '@/components/VimeoPlayer'
import get from 'lodash/get'
export default {
  name: 'Slide--Vimeo',
  props: ['slice', 'visible', 'index', 'preload'],
  components: { VimeoPlayer },
  data () {
    return {
      load: false
    }
  },
  computed: {
    ...mapGetters(['isPortrait']),
    vimeoURL () {
      const lnd = get(this.slice, 'primary.vimeo_url.url')
      const prt = get(this.slice, 'primary.vimeo_url_portrait.url')
      return this.isPortrait && prt ? prt : lnd
    },
    loadVimeo () {
      return this.vimeoURL && (this.visible || this.preload)
    },
    frmt () {
      return this.slice.primary.format
    }
  },
  methods: {
    get,
    onLoaded () {
      if (!this.visible) return this.$refs.vimeo.pause()
    },
    onFirstPlay () {
      if (this.index === 0) this.$emit('slideloaded')
    }
  },
  watch: {
    visible (vis) {
      if (vis && this.$refs.vimeo) return this.$refs.vimeo.play()
      // return this.$refs.vimeo && vis ? this.$refs.vimeo.play() : this.$refs.vimeo.pause()
    }
  }
}
</script>

<style>
</style>
