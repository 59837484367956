<template lang="pug">
  .resp-img(:class="{'relative': !bg && blurLoad, 'absolute inset-0': bg, 'overflow-hidden': !bgClass.includes('bg-contain')}")
    //- (blur as overlay)
    .resp-img__blur-overlay.absolute.inset-0.z-10.h-full.md-w-full.overflow-x-scroll(v-if="blurLoad && !lazy", style="backdrop-filter:blur(32px);", :class="{'opacity-0': !blur, 'w-full': !mobileFull}")
    //- background image
    template(v-if="bg")
      //- lazy bg
      template(v-if="lazy")
        .absolute.inset-0.bg-no-repeat(v-if="thumb", v-lazy:background-image="thumb", :class="bgClass", role="img", :aria-label="alt")
      //- bg
      .absolute.inset-0.bg-no-repeat(v-else, :style="'background-image:url(' + thumb + ')'", :class="[bgClass, {'opacity-0': !loaded}]", role="img", :aria-label="alt")
        //- hidden img to know when @loaded
        img.hidden(:src="thumb", @load="onLoaded", :alt="alt", @error="onError")
    //- <img>
    template(v-else)
      img.block.w-full(v-if="lazy && thumb", v-lazy="thumb", :alt="alt")
      img.block.trans-opacity-quick.md-w-full.max-w-none(v-else, :src="thumb", :alt="alt", :class="[mobileFull ? 'max-w-none md-max-w-full h-screen md-h-full' : 'w-full',{'opacity-0': !loaded}]", @load="onLoaded", @error="onError")
</template>

<script>
// import { prismicThumb } from '@/utils'
import { mapState } from 'vuex'
export default {
  name: 'RespImg',
  inheritAttrs: false,
  props: {
    src: { type: String, default: '' },
    alt: { type: String, default: 'Image by Guido van Helten' },
    lazy: { type: Boolean, default: false },
    bg: { type: Boolean, default: false },
    bgClass: { type: String, default: 'bg-cover bg-center bg-no-repeat' },
    useHeight: { type: Boolean, default: false },
    lngth: { type: Number, default: 0 },
    winLngth: { type: Number, default: 0 }, // use in case element is hidden to start
    blurLoad: { type: Boolean, default: true },
    mobileFull: { type: Boolean, default: false }
    // ext: { type: String, default: 'jpg' }
  },
  data () {
    return {
      length: -1,
      loaded: false,
      thumb: false,
      wait: null
    }
  },
  computed: {
    ...mapState(['winW', 'winH', 'blur']),
    resize () {
      return this.src?.includes('shopify.com') ? resizeShopifyImg : resizeImgix
    }
  },
  methods: {
    // resize (src = null) {
    //   return src && src.length && prismicThumb(src, this.length, this.useHeight)
    // },
    setLength () {
      if (this.length > 0) return
      this.length = this.lngth ? this.lngth
        : this.winLngth && this.useHeight ? this.winH * this.winLngth
          : this.winLngth ? this.winW * this.winLngth
            : this.bg && this.useHeight ? this.$el.offsetHeight
              : this.$el.offsetWidth
    },
    setThumb () {
      this.setLength()
      const size = this.useHeight ? [null, this.length] : [this.length]
      this.thumb = this.resize(this.src, size)
    },
    onLoaded () {
      this.loaded = true
      this.$emit('loaded')
    },
    onError () {
      this.thumb = this.src
    }
  },
  mounted () {
    this.setLength()
    this.setThumb()
    // this.$store.state.bus.$on('newWindowWidth', this.setLength)
  },
  destroyed () {
    // this.$store.state.bus.$off('newWindowWidth', this.setLength)
  }
}

// find image size
export function optimImgSize (length) {
  const sizes = [480, 960, 1280, 1600, 2048, 3072, 4096]
  const dpx = window.devicePixelRatio || 1
  length = length * dpx * 0.75 // less density optically ok ? (target 80%)
  // find optimal
  return sizes.find(sz => length <= sz) || sizes[sizes.length - 1]
}

// Shopify resizer
export function resizeShopifyImg (url, size = []) {
  const w = size[0] ? optimImgSize(size[0]) : ''
  const h = size[1] ? optimImgSize(size[1]) : ''
  const dot = url.lastIndexOf('.') // hopefully .jpg or .png etc
  return `${url.slice(0, dot)}_${w}x${h}${url.slice(dot)}`
}

// Imgix resizer (Prismic)
export function resizeImgix (src, size = []) {
  if (!src || !size) return src // { return console.warn('No src provided:', src) }
  try {
    src = new URL(src)
  } catch (e) {
    console.error(e)
  }
  // original specs
  const w0 = src.searchParams.get('w')
  const h0 = src.searchParams.get('h')
  // new specs
  const w = size[0] && optimImgSize(size[0])
  const h = size[1] && optimImgSize(size[1])
  // edit/set params
  if (w) {
    src.searchParams.set('w', w)
    // preserve aspect ratio ?
    if (h0 && w0) {
      src.searchParams.set('h', parseInt(w * h0 / w0))
    }
  }
  if (h) {
    src.searchParams.set('h', h)
    // preserve aspect ratio ?
    if (w0 && h0) {
      src.searchParams.set('h', parseInt(h * w0 / h0))
    }
  }
  // blur ?
  // if (blur) src.searchParams.set('blur', blur)
  //
  return src.href
}

</script>

<style>
.resp-img{
  & [lazy], & [role="img"], & img { transition: opacity 200ms, filter var(--dur-blur); }
  & [lazy="loading"]{ opacity:0; }
  & [lazy="loaded"]{ opacity:1; filter:none; }
}

.blur-media .resp-img {
  & [lazy="loaded"],
  & [role="img"],
  & img
  {
    filter:blur(32px);
  }
}

.resp-img__blur-overlay{
  transition: opacity var(--dur-blur);
}
.blur-media .resp-img__blur-overlay{
  opacity:1;
}
</style>
