import Vue from 'vue'
import VueAnalytics from 'vue-analytics'
import router from '@/router'

const gaID = process.env.VUE_APP_GOOGLE_ANALYTICS_ID

if (gaID) {
  Vue.use(VueAnalytics, {
    id: gaID,
    disabled: window.navigator.doNotTrack || false,
    set: [
      { field: 'anonymizeIp', value: true }
    ],
    router
  })
} else {
  console.warn('Missing environment variable: VUE_APP_GOOGLE_ANALYTICS_ID')
}
