<template lang="pug">
  nav.flex.justify-end.text-gray-500.whitespace-no-wrap
    router-link.hover-text-black.trans-color-fast(:to="{name: 'project', params: {uid: prev.uid}}", v-if="prev").inline-block.mr-2sp
      | Previous Project
    router-link.hover-text-black.trans-color-fast(:to="{name: 'project', params: {uid: next.uid}}", v-if="next").inline-block
      | Next Project
</template>

<script>
import { next } from '@/utils'
export default {
  name: 'ProjectNav',
  computed: {
    prjs () {
      return this.$store.state.projects
    },
    prev () {
      return this.findSib(this.prjs, this.$route.params.uid, -1)
    },
    next () {
      return this.findSib(this.prjs, this.$route.params.uid, 1)
    }
  },
  methods: {
    findSib (prjs, uid = 'xxx', step) {
      if (prjs.length < 2) return null
      const i = prjs.findIndex(doc => doc.uid === uid)
      if (i < 0) return null
      const ii = next(i, step, prjs.length) // looped step
      return prjs[ii]
    }
  }
}
</script>

<style>
</style>
