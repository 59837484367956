import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import ErrorPage from './views/Error.vue'

Vue.use(Router)

const Policy = () => import(/* webpackChunkName: "policy" */ './views/Policy.vue')

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: { headerNoCollapse: true, minH: false }
    },
    // projects
    {
      path: '/projects',
      name: 'projects',
      component: () => import(/* webpackChunkName: "projects" */ './views/Projects.vue')
    },
    {
      path: '/projects/:uid',
      name: 'project',
      component: () => import(/* webpackChunkName: "project" */ './views/Project.vue'),
      props: true
    },
    // editions
    {
      path: '/editions',
      name: 'editions',
      component: () => import(/* webpackChunkName: "editions" */ './views/Editions.vue')
    },
    // cart
    {
      path: '/cart',
      name: 'cart',
      component: () => import(/* webpackChunkName: "cart" */ './views/Cart.vue'),
      meta: { transition: 'pagefade' }
    },
    // product
    {
      path: '/editions/:uid',
      name: 'product',
      component: () => import(/* webpackChunkName: "product" */ './views/Product.vue'),
      props: true
    },
    // shop policies...
    {
      path: '/refunds',
      name: 'refunds',
      component: Policy,
      meta: { handle: 'refundPolicy', transition: 'pagefade' }
    },
    {
      path: '/shipping',
      name: 'shipping',
      component: Policy,
      meta: { handle: 'shippingPolicy', transition: 'pagefade' }
    },
    {
      path: '/privacy-policy',
      name: 'privacy',
      component: Policy,
      meta: { handle: 'privacyPolicy', transition: 'pagefade' }
    },
    {
      path: '/terms-of-service',
      name: 'terms',
      component: Policy,
      meta: { handle: 'termsOfService', transition: 'pagefade' }
    },

    // error pages
    {
      path: '/notfound',
      name: 'notfound',
      component: ErrorPage,
      meta: { title: 'Page Not Found', cta: { text: '← Back to Home', route: '/' }, transition: 'pagefade' }
    },
    {
      path: '/error',
      name: 'error',
      component: ErrorPage,
      meta: { title: 'An Error Occured', info: 'Are you connected to the internet?', transition: 'pagefade' }
    },

    // page
    {
      path: '/:uid',
      name: 'page',
      component: () => import(/* webpackChunkName: "page" */ './views/Page.vue'),
      props: true
    },

    {
      path: '*',
      redirect: '/notfound'
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    // query param to skip scrollBehavior
    if (to.query.s === 0) return
    // prj > prj - handle in component
    if (to.name === 'project' && from.name === 'project') return
    if (savedPosition) return savedPosition
    // jump to top
    // if (to.name === 'project' && from.name === 'projects') return { x: 0, y: 0 }
  }
})

router.onError(() => {
  console.error('vue-router error, redirecting to error page...')
  router.push({ name: 'error' })
})

export default router
