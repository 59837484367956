import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { canObserve } from '@/utils'
// plugins
import './plugins/vue-analytics.js'
import VueProgressBar from 'vue-progressbar'
import VueLazyload from 'vue-lazyload'
import VueScrollTo from 'vue-scrollto'
import * as VueGoogleMaps from 'vue2-google-maps'
import GmapCluster from 'vue2-google-maps/dist/components/cluster'

Vue.config.isDev = process.env.NODE_ENV === 'development'
Vue.config.bkpts = {
  // match _settings.css
  sm: 568,
  md: 768,
  lg: 1024,
  xl: 1440
}

Vue.use(VueProgressBar, { thickness: '4px', autoFinish: false, color: 'currentColor' })
Vue.use(VueLazyload, { preLoad: 2, observer: canObserve(), lazyComponent: true })
Vue.use(VueScrollTo, { duration: 800 })

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY, // TODO replace with client's
    libraries: 'places' // This is required if you use the Autocomplete plugin
  }
})
Vue.component('GmapCluster', GmapCluster)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
