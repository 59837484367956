<template lang="pug">
  article
    h1.sr-only Guido van Helten

    figure.relative.h-screen.overflow-hidden.trans-theme(v-if="doc", :class="theme === 'dark' && 'text-white'", @mousemove="onMouse", @mouseleave="hover = null", :style="{height: `${winH}px`}")
      //- media slides
      span
        component.home__slide(
          v-for="(slice, i) in slices",
          :is="slice.slice_type",
          :slice="slice",
          :index="i",
          :visible="active === i",
          :preload="isNext(i)",
          @click="onSlideClick",
          @slideloaded="onSlideLoaded",
          :class="{'home__slide--active': i === active, 'cursor-left-arrow': hover === 'left', 'cursor-right-arrow': hover === 'right'}")
      //- captions
      figcaption.fixed.z-20.left-0.bottom-0.w-full.pb-29.tracking-40.text-16
        //- header
        .relative.flex.w-full.items-center.justify-between.sm-px-12.trans-inherit
          //- .relative.flex.w-full.items-end.h-72.sm-h-auto.sm-pb-0.sm-items-start.bg-transp.trans-inherit
          .absolute.bottom-0.left-0.w-full.px-24.sm-px-12.trans-inherit.sm-static.sm-flex.lg-w-15x18(:class="{'bg-white sm-bg-transp': !collapsed}")
            h6.inline.sm-block.sm-truncate.bg-transp.mr-2sp(v-if="activeSlice")
              prismic-rich-text.inline.children-inline.child-italic-font-medium(v-if="hasTxt(activeSlice.primary.custom_caption)", :field="activeSlice.primary.custom_caption")
              span(v-else-if="activePrj.uid") <i class="font-medium">{{ txt(activePrj.data.title) }}</i>, {{ txt(activePrj.data.location) }}, {{ prjYear(activePrj) }}
            //- (custom link)
            prismic-rich-text.inline-block.hover-text-black.trans-color-fast.font-medium.text-gray-500(v-if="hasTxt(activeSlice.primary.more_text_footer_link)", :field="activeSlice.primary.more_text_footer_link")
            //- (btn: view prj)
            router-link.inline-block.hover-text-black.trans-color-fast.font-medium.text-gray-500(v-else-if="activePrj.uid", :to="{name: 'project', params: {uid: activePrj.uid}}") More
            //- more btn
            //- button.ml-2sp.trans-color-fast.hover-text-black.text-gray-500(@click="collapsed = !collapsed") {{ collapsed ? 'More' : 'Less' }}
          //- count
          .hidden.lg-block.px-12.whitespace-no-wrap.font-numbers-normal(v-if="slices") {{active + 1}}&mdash;{{slices.length}}
        //- more text (expands)
        //- .w-full.px-12(:style="{opacity: collapsed ? 0 : 1}", style="transition:opacity 500ms")
          .mt-16.sm-mt-0.pl-52.pr-24.lg-w-10x18.lg-px-12.lg-mx-auto.lg-mt-0
            //- (custom more)
            prismic-rich-text(v-if="hasTxt(activeSlice.primary.more_text_custom)", :field="activeSlice.primary.more_text_custom")
            //- (prj summary more)
            prismic-rich-text(v-else-if="activePrj.uid", :field="activePrj.data.summary")
            //- footer links
            small.mt-24.block.trans-color-quick.font-medium.text-gray-500
              //- (link)
              prismic-rich-text.inline-block.mr-2sp.hover-text-black.trans-color-fast(:field="activeSlice.primary.more_text_footer_link", v-if="hasTxt(activeSlice.primary.more_text_footer_link)")
              //- (btn: view prj)
              router-link.inline-block.hover-text-black.trans-color-fast(v-if="activePrj.uid", :to="{name: 'project', params: {uid: activePrj.uid}}") View Project
</template>

<script>
import store from '@/store'
import { mapState } from 'vuex'
import ImageSlide from '@/components/Home/Home__Slide--Image'
import VimeoSlide from '@/components/Home/Home__Slide--Vimeo'
import { next, prjYear } from '@/utils'
import _throttle from 'lodash/throttle'
import _get from 'lodash/get'
import _shuffle from 'lodash/shuffle'
import _take from 'lodash/take'
export default {
  name: 'home',
  data () {
    return {
      // doc: 0,
      active: 0,
      collapsed: true,
      hover: null,
      txt: this.$prismic.richTextAsPlain,
      prjYear,
      autoplayTmOut: null,
      afterMouse: null
    }
  },
  computed: {
    ...mapState(['theme', 'winW', 'winH']),
    doc () {
      return this.$store.state.singles['home']
    },
    slices () {
      if (!this.doc) return []
      let slices = this.doc.data.body.slice()
      slices = _shuffle(slices) // randomize order
      // move first img to front
      const i = slices.findIndex(s => s.slice_type === 'slide')
      if (i < 1) return slices // doesn't exist or is front already
      const first = slices.slice(i, i + 1)
      const before = _take(slices, i + 1)
      const after = slices.slice(i + 1, slices.length - 1)
      slices = [...first, ...before, ...after]
      return slices
    },
    activeSlice () {
      return this.slices && this.slices[this.active]
    },
    // activeIsBleed () {
    //   const actv = this.activeSlice
    //   return actv && (actv.slice_type === 'vimeo_slide' || (actv.primary.format && actv.primary.format.includes('full-')))
    // },
    activePrj () {
      return _get(this.activeSlice, 'primary.link_to')
    },
    // captionStyle () {
    //   const ht = this.$store.getters.winIs('sm') ? 7.2 : 7.2
    //   const trns = time => `background-color ${time}, transform 500ms`
    //   return {
    //     transition: this.collapsed ? trns('150ms 250ms') : trns('150ms'),
    //     transform: this.collapsed ? `translateY(calc(100% - ${ht}rem))` : 'none'
    //   }
    // },
    autoplayTimer () {
      return _get(this.doc, 'data.autoplay_timer')
    }
  },
  methods: {
    next (incr = 1) {
      this.active = next(this.active, incr, this.slices.length)
    },
    isNext (i) {
      // preload the next slide (loops to first)
      return this.active + 1 === this.slices.length ? i === 0 : this.active + 1 === i
    },
    setTheme (slice) {
      if (!slice) return
      const txt = slice.primary.text_color
      const theme = txt === 'white' ? 'dark' : null
      this.$store.commit('SET_THEME', theme)
    },
    onMouse: _throttle(function (e) {
      // set hover left/right
      const isLeft = e.screenX <= this.winW * 0.5 // assuming overlay is full window
      this.hover = isLeft ? 'left' : 'right'
      // pause autoplay
      this.pause()
      clearTimeout(this.afterMouse)
      this.afterMouse = setTimeout(() => this.autoplay(), 300)
    }, 100),
    onSlideClick () {
      if (this.hover === 'left') return this.next(-1)
      // forward
      this.next(1)
      this.autoplay()
    },
    hasTxt (field) {
      const txt = field && this.$prismic.richTextAsPlain(field)
      return txt && txt.length
    },
    autoplay () {
      this.pause()
      if (!this.autoplayTimer) return
      const timer = this.autoplayTimer * 1000 // convert to ms
      this.autoplayTmOut = setTimeout(() => {
        this.next(1)
        this.autoplay()
      }, timer)
    },
    pause () {
      clearTimeout(this.autoplayTmOut)
    },
    onSlideLoaded () {
      this.$store.commit('PROGRESS_FINISH')
      this.setTheme(this.activeSlice)
      this.autoplay()
    }
  },
  watch: {
    activeSlice (slice) {
      this.setTheme(slice)
      this.collapsed = true
    }
  },
  beforeRouteEnter (to, from, next) {
    store.commit('PROGRESS_START')
    store.dispatch('getSingle', 'home').then(() => next())
  },
  beforeRouteLeave (to, from, next) {
    this.$store.commit('SET_THEME')
    next()
  },
  components: {
    'slide': ImageSlide,
    'vimeo_slide': VimeoSlide
  }
}
</script>

<style>
.home__slide{
  opacity:0;
  transition: opacity 400ms;
  /* entering */
  &.home__slide--active{
    opacity:1;
    transition: opacity 400ms 400ms;
    z-index:2;
  }
}

</style>
