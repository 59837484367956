<template lang="pug">
  .vimeo-player.trans-opacity-quick(:class="{'opacity-0': invisible, 'absolute inset-0 h-full w-full': bgSize, 'vimeo-player--active': playing !== null}")
    //- frame
    .vimeo-player__frame.overflow-hidden(ref="vimeo", :class="{'relative': !bgSize, 'pb-ar-16x9': !bgSize && !loaded, 'absolute': bgSize, 'vimeo-player__frame--blurred': blur}", :style="!bgSize && ratio && {paddingBottom: `calc(100% * ${ratio})`}")
      //- <iframe> will be injected
      //- controls
      .vimeo-player__controls.absolute.inset-0.flex.items-center.justify-center.z-10.trans-opacity-quick(v-if="controls", :class="{'opacity-0': blur}")
        //- play/pause
        button(v-show="!playing", @click="playBtn", :disabled="blur", aria-label="Play")
          img.select-none(src="@/assets/icons/play-btn.svg")
        button(v-show="playing === true", @click="pause", aria-label="Pause")
          img.select-none(src="@/assets/icons/pause-btn.svg")
        //- mute, progress
        .absolute.w-full.bottom-0.left-0.flex.justify-center.pb-18(v-if="playing !== null")
          .w-11x18.flex.items-center.justify-between
            .p-4
              button(v-show="muted", @click="unmute", aria-label="Mute")
                img.select-none(src="@/assets/icons/is-muted.svg")
              button(v-show="!muted", @click="mute", aria-label="Mute")
                img.select-none(src="@/assets/icons/audio.svg")
            progress.flex-1.h-4.cursor-pointer(max="100", :value="prgrss", @click="onProgressClick")
</template>

<script>
import Player from '@vimeo/player'
import { mapState } from 'vuex'
export default {
  name: 'VimeoPlayer',
  props: ['vimeoURL', 'bgSize', 'clip', 'controls', 'progress', 'visible'],
  data () {
    return {
      player: null,
      loaded: false,
      invisible: true,
      blur: null,
      elW: 0,
      elH: 0,
      videoW: 0,
      videoH: 0,
      iframe: null,
      playing: null,
      prgrss: 0,
      muted: true
    }
  },
  computed: {
    ...mapState({ globalBlur: 'blur' }),
    elRatio () {
      return this.elH / this.elW
    },
    ratio () {
      return this.videoH / this.videoW
    }
  },
  methods: {
    init () {
      if (!this.vimeoURL) return console.warn('No vimeo URL')
      const options = {
        url: this.vimeoURL,
        background: true,
        loop: true
      }
      if (this.bgSize === 'cover') {
        options.maxheight = this.elH * 0.8
      } else {
        options.maxwidth = this.elW
      }
      // create player
      this.player = new Player(this.$refs.vimeo, options)
      // events
      this.player.on('loaded', () => {
        this.loaded = true
        this.setClip()
        this.$emit('loaded')
        this.resizeIframe() // resize and reveal
      })
      this.player.on('timeupdate', this.onTimeUpdate)
      this.player.on('timeupdate', this.onFirstPlay) // binding to @play is inconsistent !
    },
    play () {
      return this.player && this.player.getPaused().then(paused => paused && this.player.play())
    },
    pause () {
      return this.player && this.player.getPaused().then(paused => !paused && this.player.pause())
    },
    measure () {
      this.elW = this.$el.offsetWidth
      this.elH = this.$el.offsetHeight
    },
    resizeIframe () {
      this.iframe = this.iframe || this.$el.querySelector('iframe')
      const iframe = this.iframe
      const size = this.bgSize
      if (!iframe) return
      // get aspect ratio
      if (!this.videoW) {
        this.videoW = iframe.getAttribute('width')
        this.videoH = iframe.getAttribute('height')
        // console.log(this.videoW, this.videoH)
      }
      // resize (if background)
      if (size) {
        const isWider = this.ratio <= this.elRatio // video wider than frame
        const scaleByHt = (size === 'cover' && isWider) || (size === 'contain' && !isWider)
        // scale the other dimension
        let sideB = this.elH * this.videoW / this.videoH // width (landscape video)
        sideB = this.ratio >= 1 ? this.elW * this.videoH / this.videoW : sideB // height (portrait video)
        if (scaleByHt) {
          iframe.setAttribute('height', this.elH)
          iframe.setAttribute('width', parseInt(sideB))
        } else {
          iframe.setAttribute('height', parseInt(sideB))
          iframe.setAttribute('width', this.elW)
        }
      }
      this.invisible = false
    },
    setClip () {
      // jump to clip start (if set)
      // this.clip = [start, end]
      if (this.clip && this.clip[0] !== undefined && this.clip[1]) {
        this.player.setCurrentTime(this.clip[0])
      }
    },
    onFirstPlay () {
      /**
       * Function to emit when video has visible imagery
       * - bind to 'timeupdate' because 'play' event fires inconsistently...
      **/
      const finish = () => {
        this.blur = false
        this.$Progress.finish()
      }
      // remove handler
      this.player.off('timeupdate', this.onFirstPlay)
      // skip if vimeo not visible yet
      if (!this.visible) return finish()
      // emit event (functions as "isLoaded")
      this.$emit('firstplay')
      setTimeout(finish(), 800)
    },
    onTimeUpdate (time) {
      // TODO - maybe don't update if in clip mode still ?
      this.prgrss = time.percent * 100
      // if in clip mode - loop back to clip start on clip end
      if (this.playing === null) {
        const end = this.clip[1]
        return end && time.seconds >= end && this.player.setCurrentTime(this.clip[0])
      }
    },
    playBtn () {
      const plyr = this.player
      // disable clip mode, set time to 0
      if (this.playing === null) {
        this.playing = true
        this.$Progress.start()
        return plyr.setCurrentTime(0).then(time => {
          this.$Progress.finish()
          plyr.on('play', () => { this.playing = true })
          plyr.on('pause', () => { this.playing = false })
        })
      }
      return plyr.play()
    },
    onProgressClick (e) {
      const pct = e.offsetX / e.target.offsetWidth
      this.player.getDuration().then((dur) => {
        // console.log(dur)
        this.$Progress.start()
        this.player.setCurrentTime(pct * dur).then(time => {
          // console.log('time', time)
          this.$Progress.finish()
        })
      }).catch(err => console.error(err))
    },
    mute () {
      this.player.setVolume(0).then(() => { this.muted = true })
    },
    unmute () {
      this.player.setVolume(1).then(() => { this.muted = false })
    },
    onNewWindowWidth () {
      // if (this.bgSize === 'cover') return
      this.measure()
      this.resizeIframe()
    }
  },
  created () {
    if (this.progress) this.$Progress.start()
    this.blur = this.globalBlur || this.visible
    // console.log('blur load?', this.blur, this.globalBlur, this.visible)
  },
  mounted () {
    this.measure()
    this.init()
    this.$store.state.bus.$on('newWindowWidth', this.onNewWindowWidth)
  },
  destroyed () {
    this.$store.state.bus.$off('newWindowWidth', this.onNewWindowWidth)
  }
}
</script>

<style>
.vimeo-player__frame{
  &.absolute{
    top:50%; left:50%;
    transform: translate(-50%, -50%);
  }
  & iframe {
    transition: filter 600ms;
    pointer-events: none !important;
  }
  &.vimeo-player__frame--blurred iframe{
    filter:blur(6px);
  }
  &.relative iframe{
    position: absolute;
    top:0; left:0;
    width:100% !important;
    height:100% !important;
  }
}

/* show/hide controls */
.vimeo-player--active{
  & .vimeo-player__controls{opacity:0;}
  & .vimeo-player__frame:hover .vimeo-player__controls{opacity:1;}
}

/* style video progress bar */
.vimeo-player progress{
  appearance:none;
  &[value]::-webkit-progress-bar{
    background: rgba(0,0,0,.5);
  }
  &[value]::-webkit-progress-value{
    background:white;
  }
}

/* blur iframe on rt loading */
.blur-media .vimeo-player {
  & iframe {
    filter:blue(6px);
  }
}
</style>
