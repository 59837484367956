import Vue from 'vue'
import Vuex from 'vuex'
import PrismicVue from 'prismic-vue'
import linkResolver from '@/prismic-link-resolver'
import { formatPrjs } from '@/utils'
// modules
import shop from './shop'
// import products from './products'

const vue = new Vue()
const eventBus = new Vue()

Vue.use(PrismicVue, {
  endpoint: process.env.VUE_APP_PRISMIC_API,
  linkResolver
})

Vue.use(Vuex)

export default new Vuex.Store({
  modules: { shop },
  state: {
    bus: eventBus,
    blur: true, // why was false?
    theme: 'light',
    winW: window.innerWidth,
    winH: window.innerHeight,

    singles: {},
    pages: [],
    projects: [],

    viewer: false,
    viewerAt: null,

    nav: null
  },
  getters: {
    winIs: (state) => (name) => {
      const _ = Vue.config.bkpts
      return name === 'portrait' ? state.winW < state.winH
        : name === 'xl' ? state.winW >= _.xl
          : name === 'lg' ? state.winW >= _.lg
            : name === 'sm' ? state.winW >= _.sm
              : undefined
    },
    // TODO use winIs
    isPortrait: state => state.winW < state.winH
  },
  mutations: {
    SET_THEME (state, theme = 'light') {
      state.theme = theme
    },
    SET_WIN_W (state, val) {
      state.winW = val
    },
    SET_WIN_H (state, val) {
      state.winH = val
    },
    SET_BLUR (state, val) {
      const delay = val === false ? 800 : 0 // after css transition --dur-blur
      setTimeout(() => { state.blur = val }, delay)
    },
    ADD_SINGLE (state, _) {
      const singles = { ...state.singles }
      singles[_.type] = _.doc
      state.singles = singles
    },
    SET_PROJECTS (state, docs) {
      state.projects = formatPrjs(docs)
    },
    ADD_PROJECT (state, doc) {
      state.projects.push(formatPrjs([doc]))
      state.projects.sort((a, b) => {
        if (!a.data || !b.data) return 0
        a = new Date(a.data.published_date)
        b = new Date(b.data.published_date)
        return a > b ? -1 : a < b ? 1 : 0
      })
    },
    ADD_PAGE (state, doc) {
      state.pages.push(doc)
    },
    OPEN_VIEWER (state, id) {
      state.viewer = true
      state.viewerAt = id
    },
    CLOSE_VIEWER (state) {
      state.viewer = false
      setTimeout(() => { state.viewerAt = null }, 1000)
    },
    RESET_VIEWER (state) {
      state.viewer = false
      state.viewerAt = null
    },
    SET_NAV (state, val) {
      state.nav = val
    },
    PROGRESS_START () {
      vue.$Progress.start()
    },
    PROGRESS_FINISH () {
      vue.$Progress.finish()
    },
    PROGRESS_FAIL () {
      vue.$Progress.fail()
    }
  },
  actions: {
    async getSingle ({ state, commit }, type) {
      if (state.singles[type]) return state.singles[type]
      return vue.$prismic.client.query(
        vue.$prismic.Predicates.at('document.type', type),
        { fetchLinks: ['project.title', 'project.summary', 'project.mural_completion_date', 'project.location', 'product.title', 'product.description', 'product.image'] }
      ).then(({ results }) => {
        if (!results[0]) return null
        commit('ADD_SINGLE', { type: type, doc: results[0] })
        return results[0]
      })
    },
    async getProjects ({ state, commit }) {
      if (state.projects.length) return true
      return vue.$prismic.client.query(
        vue.$prismic.Predicates.at('document.type', 'project'),
        { pageSize: 100, orderings: '[my.project.published_date desc]' }
      ).then(({ results }) => commit('SET_PROJECTS', results))
    },
    async getProject ({ state, commit }, uid) {
      const svd = state.projects.find(doc => doc.uid === uid)
      if (svd) return svd
      return vue.$prismic.client.getByUID('project', uid)
        .then(doc => {
          if (doc) commit('ADD_PROJECT', doc)
          return doc
        })
        // handle error in component
    },
    async getPage ({ state, commit }, uid) {
      const svd = state.pages.find(doc => doc.uid === uid)
      if (svd) return svd
      return vue.$prismic.client.getByUID('page', uid)
        .then(doc => {
          commit('ADD_PAGE', doc)
          return doc
        })
        .catch(console.error)
    },
    async getNextProject ({ state }, { date, reverse = false }) {
      // next = older
      const field = 'my.project.published_date'
      const predicate = reverse
        ? vue.$prismic.Predicates.dateBefore(field, date) // older
        : vue.$prismic.Predicates.dateAfter(field, date) // younger
      return vue.$prismic.client.query(predicate)
        .then(({ results }) => results[0])
    },
    addSubscriber (_, data) {
      return fetch('/add-subscriber', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' }
      })
        .then(resp => resp.json().then(data => ({ status: resp.status, body: data })))
        .catch(err => console.error('Subscribe Network Error:', err))
    }
  }
})
