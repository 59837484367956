<template lang="pug">
  .app-header-mobile.relative

    //- header
    .relative.z-10.flex.h-72.w-full.items-center.justify-between.px-12(:class="[theme === 'dark' && !menu ? 'text-white' : 'text-black', {'bg-white': y > 0 && canCollapse && !collapsed}]", style="transition: color 200ms, background 100ms")
      //- title
      .px-12.text-21.tracking-40.pt-2.font-medium
        a(href="/", @click.prevent="$emit('refresh')") Guido van Helten
      //- right btns
      .flex(style="transition: opacity 100ms", :class="{'opacity-0': collapsed}")
        //- bag btn
        router-link.block.p-12.content-box(:to="{name: 'cart'}")
          svg-bag.block(style="height:2.3rem")
        //- menu btn
        button.p-12.content-box.flex.items-center.justify-center.w-25.h-29(aria-label="Menu", @click="menu = !menu", :class="{'opacity-0': hideNav, 'pointer-events-none': collapsed}", :disabled="hideNav || collapsed")
          span.block.w-25.h-16.border-t-2.border-b-2.border-current(v-show="!menu")
          svg-x.w-20.h-20(v-show="menu")

    //- (menu overlay)
    transition(name="quickfade")
      nav.absolute.top-0.left-0.h-screen.w-full.bg-white.pt-72.overflow-y-scroll(v-show="menu")
        ul.list-reset.leading-wide.px-24.pb-24.text-16
          li
            router-link(:to="{name: 'home'}", @click.native="menu = false") Overview
          li
            router-link.app-header-mobile__parent-rt(:to="{name: 'projects'}", @click.native="menu = false") Projects
            ul.list-reset.ml-tab
              li
                router-link(:to="{name: 'projects', query: {view: 'image'}}", :class="{'font-medium': $route.name === 'projects' && !$route.query.view}", @click.native="menu = false") Image
              li
                router-link(:to="{name: 'projects', query: {view: 'map'}}", :class="{'font-medium': $route.query.view === 'map'}", @click.native="menu = false") Map
              li
                router-link(:to="{name: 'projects', query: {view: 'text'}}", :class="{'font-medium': $route.query.view === 'text'}", @click.native="menu = false") Text
          li
            router-link(:to="{name: 'page', params: {uid: 'info'}}", @click.native="menu = false") Information
            ul.list-reset.ml-tab
              li(v-for="(itm, i) in infoNav")
                router-link(:to="{name: 'page', params: {uid: 'info'}, hash: '#' + idy(itm) }", @click.native="menu = false") {{itm}}
          li
            router-link(:to="{name: 'page', params: {uid: 'press'}}", @click.native="menu = false") Press
          li
            router-link(:to="{name: 'editions'}", @click.native="menu = false") Editions

</template>

<script>
import { mapState } from 'vuex'
import { idy, getNav } from '@/utils'
import svgX from '@/components/Icons/SVG-X'
import svgBag from '@/components/Icons/SvgBag'
export default {
  name: 'AppHeader--Mobile',
  props: ['hideNav', 'collapsed', 'y', 'canCollapse'],
  data () {
    return {
      menu: false,
      infoNav: [],
      idy
    }
  },
  computed: {
    ...mapState(['theme'])
  },
  watch: {
    '$route' () {
      this.menu = false
    }
  },
  created () {
    this.$store.dispatch('getPage', 'info').then(doc => {
      this.infoNav = getNav(doc.data.body)
    })
  },
  components: { svgX, svgBag }
}
</script>

<style>
.app-header-mobile .router-link-exact-active,
.app-header-mobile .app-header-mobile__parent-rt.router-link-active {
  font-weight: 500
}
</style>
