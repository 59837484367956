<template lang="pug">
  .absolute.inset-0(@click="$emit('click')")
    //- full bleed
    template(v-if="format === 'full-bleed'")
      resp-img(v-if="image", :bg="true", :winLngth="1", :useHeight="isPortrait", :src="image.url", :alt="image.alt", :key="image.url", @loaded="onLoaded")
    //- has margins
    template(v-else)
      .absolute.top-72.left-0.w-full.px-24.lg-px-72.h-screen_-144.flex.items-center.overflow-hidden
        //- blur-image-frame.w-full(v-if="image", :dims="image.dimensions")
        resp-img.w-full(:src="image.url", :alt="image.alt", :winLength=".9", @loaded="onLoaded")
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import BlurImageFrame from '@/components/BlurImageFrame'
import RespImg from '@/components/RespImg'
export default {
  name: 'Slide--Image',
  props: ['slice', 'visible', 'index'],
  components: { RespImg, BlurImageFrame },
  computed: {
    ...mapState(['winW', 'winH']),
    ...mapGetters(['isPortrait']),
    image () {
      const isBleed = this.format === 'full-bleed'
      const image = this.slice.primary.image
      const portraitImg = image.portrait || image
      return isBleed && this.isPortrait ? portraitImg : image
    },
    format () {
      return this.slice.primary.format
    },
    winIsWiderAR () {
      const dims = this.image && this.image.dimensions
      if (!dims) return undefined
      return this.winH / this.winW < dims.height / dims.width
    }
  },
  methods: {
    onLoaded () {
      if (this.index === 0) this.$emit('slideloaded')
    }
  }
}
</script>

<style>
</style>
